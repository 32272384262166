<template>
	<el-dialog title="入驻视频教程" :visible="dialogVisible" :before-close="handleClose" width="50%">
		<video ref="video" width="100%" height="auto" controls>
			<source :src="src" type="video/mp4">
			您的浏览器不支持Video标签。
		</video>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			src: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
			};
		},
		computed: {
		},
		created() {},
		mounted() {},
		methods: {
			// 关闭弹框
			handleClose(done) {
				this.$refs.video.pause();
				this.$emit("closeDialog")
				done();
			}
		}
	};
</script>

<style lang="scss" scoped>
</style>
