<!--<template>
	<div class="page-mall-home">
		<el-button type="primary" @click="handleJoinMallStep">立即入驻</el-button>
		<el-button type="primary" @click="jump2page('了解流程')">了解流程</el-button>
		<el-button type="primary" @click="jump2page('马上查询')">马上查询</el-button>
		<el-button type="primary" @click="jump2page('马上联系')">马上联系</el-button>
		<el-button type="primary" @click="jump2page('立即报名')">立即报名</el-button>
		<el-button type="primary" @click="jump2page('查看更多')">查看更多</el-button>
		<br />
		<br />
		<br />
		<el-button type="primary" @click="handleVisible">入驻指导</el-button>
		<el-button type="primary" @click="jump2page('StepPreInfo')">入驻前置页面</el-button>

		<Login v-if="loginIsShow" :loginIsShow="loginIsShow" @loginVisible="handleLoginVisibleFlag" />
		<MerchantVideo :src="mp4src" :dialog-visible="dialogVisible" @closeDialog="handleVisible" />
	</div> 
</template>-->

 <script>
// import Login from "@/components/Login";
// import MerchantVideo from "@/components/MerchantVideo.vue";
// import { validatenull } from "@/utils/validate";
// import {
// 	getInfoByUserId,
// 	getEnterApply,
// 	getByBusList,
// 	getApplyInfo,
// 	getBrandInfo,
// } from "@/api";
// import { getInvestmentTopList } from "@/api/api-join-mall";
// export default {
// 	components: {
// 		Login,
// 		MerchantVideo,
// 	},
// 	computed: {
// 		userName() {
// 			return this.$store.state.userName;
// 		},
// 	},
// 	data() {
// 		return {
// 			mp4src: "http://mp4.vjshi.com/2021-05-13/d0e55b2f8bdd2c536394ef93e1a3161a.mp4",
// 			hasLogin: false,
// 			loginIsShow: false,
// 			dialogVisible: false,
// 			merchantData: {},
// 			data1: {},

// 			// 招商会推荐列表
// 			investmentTopList: [],
// 		};
// 	},
// 	methods: {
// 		/**
// 		 * 登录组件
// 		 */
// 		handleLoginVisibleFlag(status) {
// 			this.loginIsShow = status;
// 		},
// 		/**
// 		 * 视频指导
// 		 */
// 		handleVisible() {
// 			this.dialogVisible = !this.dialogVisible;
// 		},
// 		/**
// 		 * 招商会推荐列表
// 		 */
// 		getInvestmentTopList() {
// 			getInvestmentTopList().then((res) => {
// 				if (res.data.code === 0) {
// 					this.investmentTopList = res.data.data || [];
// 					// 商会状态(0:未开始，1:已开始，2:已结束)
// 				}
// 			});
// 		},
// 		/**
// 		 * 立即入驻，跳转step
// 		 */
// 		async handleJoinMallStep() {
// 			if (this.userName) {
// 				this.loginIsShow = false;
// 				await this.getInfoByUserId();
// 				await this.getEnterList();
// 			} else {
// 				this.loginIsShow = true;
// 			}
// 		},
// 		/**
// 		 * 获取用户填写过的信息，仅跳转到前置信息页面
// 		 * 前置信息、公司联系人信息、企业信息、品牌资质、已提交
// 		 */
// 		async getInfoByUserId() {
// 			// 获取商户信息
// 			const res = await getInfoByUserId();
// 			const saveInfo = JSON.parse(localStorage.getItem("merchant_info"));
// 			if (res && res.data.code === 0) {
// 				this.data1 = res.data.data;
// 				this.data1.linkmanPhone = this.data1.linkmanPhone
// 					? this.data1.linkmanPhone
// 					: sessionStorage.getItem("user_phone");
// 				this.$set(
// 					this.data1,
// 					"coLinkmanPhone",
// 					this.data1.linkmanPhone
// 				);
// 				this.$set(this.data1, "coLinkmanMail", this.data1.linkmanMail);
// 				localStorage.setItem(
// 					"merchant_info1",
// 					JSON.stringify(this.data1)
// 				);
// 				this.data1 = {
// 					...saveInfo,
// 					...this.data1,
// 				};
// 				localStorage.setItem(
// 					"merchant_info",
// 					JSON.stringify(this.data1)
// 				);
// 				this.merchantData = { ...this.data1 };
// 			}
// 		},
// 		async getEnterList() {
// 			// 获取入驻申请列表
// 			const res = await getEnterApply();
// 			if (res.data.code === 0) {
// 				const records = res.data.data.records;
// 				if (records.length === 0) {
// 					if (this.data1.merId) {
// 						this.$router.push("companyInfo");
// 					} else {
// 						this.$router.push("/index/merchantInfo");
// 					}
// 					return;
// 				}
// 				this.merchantData = {
// 					...this.getCurrentApply(res.data.data.records),
// 				};
// 				// 根据业务ID获取审批履历信息
// 				const res2 = await getByBusList(this.merchantData.entryId);
// 				if (res2.data.code === 0) {
// 					this.merchantData = {
// 						...this.merchantData,
// 						auditList: res2.data.data,
// 					};
// 				}
// 				// 获取入驻申请信息详情
// 				const res3 = await getApplyInfo(this.merchantData.entryId);
// 				if (res3.data.code === 0) {
// 					let data = res3.data.data;
// 					if (
// 						!data.entryBrandCaUpdateVos ||
// 						data.entryBrandCaUpdateVos.length === 0
// 					)
// 						return;
// 					for (
// 						var i = 0;
// 						i < data.entryBrandCaUpdateVos.length;
// 						i++
// 					) {
// 						if (!data.entryBrandCaUpdateVos[i].brandId) return;
// 						// 根据ID查询品牌信息
// 						const brandInfo = await getBrandInfo(
// 							data.entryBrandCaUpdateVos[i].brandId
// 						);
// 						if (brandInfo.data.code !== 0) return;
// 						data.entryBrandCaUpdateVos[i] = {
// 							...data.entryBrandCaUpdateVos[i],
// 							...brandInfo.data.data,
// 						};
// 						let path = data.entryBrandCaUpdateVos[i].trademarkPath;
// 						if (path && typeof path === "string") {
// 							path = JSON.parse(path);
// 							data.entryBrandCaUpdateVos[i].trademarkPath =
// 								!validatenull(path) ? [path] : [];
// 						}
// 					}
// 					const options = {
// 						bankAddress: [
// 							data.bankAddrProvince,
// 							data.bankAddrCity,
// 							data.bankAddrCounty,
// 						],
// 						coRegAddress: [
// 							data.coRegAddrProvince,
// 							data.coRegAddrCity,
// 							data.coRegAddrCounty,
// 						],
// 						ticketAddress: [
// 							data.ticketAddrProvince,
// 							data.ticketAddrCity,
// 							data.ticketAddrCountry,
// 						],
// 						licResIdList: data.licResId
// 							? [data.licResId]
// 							: data.licResIdList,
// 						cardJustList: data.idCardJustPath
// 							? [data.idCardJustPath]
// 							: [],
// 						cardBackList: data.idCardBackPath
// 							? [data.idCardBackPath]
// 							: [],
// 					};
// 					this.merchantData = {
// 						...this.merchantData,
// 						...data,
// 						...options,
// 					};
// 				}
// 			}
// 			// status=> 1 待审核  2 审核通过  9 审核驳回,
// 			// 无论什么状态都跳抓到submitInfo
// 			localStorage.setItem(
// 				"merchant_info",
// 				JSON.stringify(this.merchantData)
// 			);
// 			this.$router.push("submitInfo");
// 		},
// 		getCurrentApply(list) {
// 			if (list.length === 0) return {};
// 			if (list.length === 1) return list[0];
// 			list.sort(function (a, b) {
// 				return b.createTime < a.createTime ? -1 : 1;
// 			});
// 			return list[0];
// 		},
// 		/**
// 		 * 跳转页面
// 		 */
// 		jump2page(name) {
// 			this.$router.push({ name });
// 		},
// 	},
// 	created() {
// 		this.getInvestmentTopList();
// 	},
// };
 </script>

<!--  <style lang="scss" scoped>
 .page-mall-home {
 }
 </style> -->

<template>
  <div class="body">
    <div class="top-bg bg_1">
      <div class="top-content">
        <div class="top-title">{{ title }}</div>
        <div class="top-subTitle">
          <div
            v-for="(item, index) in subTitle"
            :key="`title${item.id}`"
            class="flex"
          >
            <div class="sub-box">
              <div class="sub-content">
                {{ item.content
                }}<span style="letter-spacing: 0">{{ item.lastWord }}</span>
              </div>
            </div>
            <div v-if="subTitle.length - 1 > index" class="sub-line"></div>
          </div>
        </div>
        <div class="in-btn" @click="downloadApp">
          <span>立即入驻</span>
          <i></i>
        </div>
      </div>
    </div>
    <div class="nav-box">
      <div v-for="item in navList" :key="`nav${item.id}`" class="nav-list">
        <el-image
          :src="require(`@/assets/img/nav${item.icon}.png`)"
          fit="fill"
        ></el-image>
        <div class="nav-text">{{ item.content }}</div>
      </div>
    </div>
    <!--招商入驻指导-->
    <div class="introduce-height">
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">SETTLED</h1>
          <h3 class="top_card-title">招商入驻指导</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="bottom-carousel-card">
          <div class="gome_project-content-body">
            <!-- 左箭头按钮 -->
            <div class="prev carousel-button swiper-button-prev">
              <el-button
                type="info"
                icon="el-icon-arrow-left icon-font"
                circle
              ></el-button>
            </div>
            <!-- 右箭头按钮 -->
            <div class="next carousel-button swiper-button-next">
              <el-button
                type="info"
                icon="el-icon-arrow-right icon-font"
                circle
              ></el-button>
            </div>
            <!-- 轮播图 -->
            <div class="swiper-box">
              <div class="last-shadow"></div>
              <swiper :options="swiperOption">
                <swiper-slide
                  v-for="(item, index) in cardList"
                  :key="`card${index}`"
                >
                  <el-image
                    :src="require(`@/assets/img/card${item.img}.png`)"
                    fit="fill"
                    class="carousel-card"
                  >
                  </el-image>
                  <div class="carousel-info">
                    <div class="carousel-info-title text">{{ item.title }}</div>
                    <div class="carousel-info-content text">
                      {{ item.content }}
                    </div>
                    <div
                      class="videoBtn"
                      v-if="item.id === 1"
                      @click.prevent="showVideo"
                    >
                      <div class="play"></div>
                    </div>
                    <div class="applyBtn" @click="jumpUrl(item.jump)" v-else>
                      {{ item.button }}
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div
              class="downAppBtn"
              style="margin: 60px auto 0 auto"
              @click="downloadApp"
            >
              <span>立即入驻</span>
              <i></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--招商会-->
    <div class="gray-bg investment" v-if="items && items.length > 0">
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">INVESTMENT</h1>
          <h3 class="top_card-title">招商会</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="bottom_card top between">
          <div
            class="news-content-con"
            v-for="item in items"
            :key="`news${item.id}`"
          >
            <div class="news-content-box">
              <el-image
                class="news-img"
                :src="require(`@/assets/img/${item.conferenceImg}.png`)"
                fit="fill"
              >
              </el-image>
              <div class="news-content-con-title">
                <h3 class="news-content-con-title-name">
                  {{ item.conferenceTitle }}
                </h3>
                <div class="news-content-con-title-time">
                  时间：{{ item.holdStartTime }}
                </div>
                <div class="news-content-con-title-des">
                  地点：{{ item.conferenceLocation }}
                </div>
                <h2
                  class="news-content-con-sign"
                  @click="jump2conferenceDetail(item.id)"
                >
                  立即报名
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div
          class="gome_news-more"
          v-show="items.length >= 6"
          @click="enter_news"
        >
          查看更多
        </div>
      </div>
    </div>
    <!--优秀案例-->
    <div class="introduce-height">
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">CASE</h1>
          <h3 class="top_card-title">优秀案例</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="case-bottom top75">
          <div
            class="case-content-con"
            v-for="item in caseList"
            :key="item.id"
            @click="jump2casesDetail(item.id)"
          >
            <el-image
              class="case-img"
              :src="item.image"
              fit="fill"
            >
            </el-image>
            <div class="case-content-box">
              <div class="case-content-title">
                {{ item.title }}
              </div>
              <div class="case-content-des">{{ item.des }}</div>
              <div class="case-content-time">{{ item.date }}</div>
              <div class="case-content-sign">阅读全部 ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--多平台支持-->
    <div class="gray-bg platform">
      <div class="gome_introduce">
        <div class="top_card">
          <h1 class="top_card-entitle">PLATFORM</h1>
          <h3 class="top_card-title">多平台支持</h3>
          <div class="bottom_line"></div>
        </div>
        <div class="box-out" style="display: flex">
          <div class="code-box code-right">
            <div class="code-title">国美家公众号</div>
            <el-image
              class="code-pic"
              :src="require('@/assets/img/gongzonghao.png')"
              fit="fill"
            ></el-image>
            <div class="code-info">扫描二维码关注公众号</div>
          </div>
          <div class="code-box code-right">
            <div class="code-title">国美家APP</div>
            <el-image
              class="code-pic"
              :src="require('@/assets/img/qr_lohashow.png')"
              fit="fill"
            ></el-image>
            <div class="code-info">扫描二维码下载APP</div>
          </div>
          <div class="code-box">
            <div class="code-title">国美家小程序</div>
            <el-image
              class="code-pic"
              :src="require('@/assets/img/qr/lohashow.jpg')"
              fit="fill"
            ></el-image>
            <div class="code-info">扫描太阳码打开小程序</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录 -->
    <Login
      v-if="loginIsShow"
      :loginIsShow="loginIsShow"
      @loginVisible="loginVisible"
    />
    <MerchantVideo
      :src="src"
      :dialog-visible="dialogVisible"
      @closeDialog="handleVisible"
    />
  </div>
</template>

<script>
// Import Swiper Vue.js components
import MerchantVideo from "@/components/MerchantVideo.vue";
import { getTopListApi } from "@/api/api-join-mall";
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import "swiper/dist/css/swiper.css";
import Login from "@/components/Login.vue";
export default {
  data () {
    return {
      dialogVisible: false, //入驻视频弹出框是否显示
      src:
        "http://mp4.vjshi.com/2021-05-13/d0e55b2f8bdd2c536394ef93e1a3161a.mp4",
      loginIsShow: false, //登录弹出框是否显示
      title: "国美家商Mall入驻",
      subTitle: [
        { id: 1, content: '海量流', lastWord: '量' },
        { id: 2, content: '快速开', lastWord: '店' },
        { id: 3, content: '营销扶', lastWord: '持' },
      ],
      navList: [
        { id: 1, icon: '1', content: '共享餐饮' },
        { id: 2, icon: '2', content: '共享零售' },
        { id: 3, icon: '3', content: '共享宠物' },
        { id: 4, icon: '4', content: '共享教育' },
        { id: 5, icon: '5', content: '共享娱乐' },
      ],
      swiperOption: {
        //loop: true,// 设置循环点击
        slidesPerView: 'auto',//自适应宽度
        spaceBetween: 25,
        // 设置前进后退的按钮（可点击跳转）
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

      },
      cardList: [
        { id: 1, button: '', title: '国美家招商入驻指南', content: '', img: '1' },
        { id: 2, button: '了解流程', title: '入驻流程抢先看', content: '抢先查看入驻流程、招商规划等内容，一览开店全流程', img: '2', jump: '/process' },
        { id: 3, button: '马上查询', title: '资费资质快速查询', content: '详细了解入驻所需费用和材料信息，一次准备齐全', img: '3', jump: '/qualification' },
        { id: 4, button: '马上联系', title: '招商联系人一键查找', content: '快速找到招商经理咨询入驻信息，享受一对一专属服务', img: '4', jump: '/questions/contactList' },
      ],
      items: [],
      caseList: [
        {
          id: 1,
          image: require('./assets/img/cases/demo1/2.png'),
          title: "开业45天，日销连续稳居前三，高于平均销售额120%的商户是如何经营的？",
          des: "自国美家美食美客商务食堂出现在大众视野。“香鼎坊”作为入驻品牌之一，在过去45天的经营过程中业绩 遥遥领先，在销售额和订单量上⻓期居于前列，交出了亮眼的答卷。",
          date: "2021.09.07",
        },
        {
          id: 2,
          image: require('./assets/img/cases/demo2/2.png'),
          title: "高出平均销售额220%+，揭秘快充时刻如何成为“食堂爆款”！",
          des: "2021年10月，快充时刻在美食美客食堂营业仅30 天，就以销售额高于平均水平220%的优势展现出了惊人的爆发力，成为美食美客最受欢迎的快餐品牌之一。",
          date: "2021.10.15",
        },
        {
          id: 3,
          image: require('./assets/img/cases/demo3/1.jpg'),
          title: "商户版-国美家商户扶持活动-美丽雅",
          des: "美丽雅女王节活动”是国美家与成都家居清洁用品龙头企业美丽雅，基于3月8日妇女节打造的“女王节”推广活动。本次活动主要通过纯线上的社群运营在全国范围内推广，是美丽雅＆国美家首次合作，也是国美家商户扶持政策的重要组成部分。",
          date: "2022.03.08",
        }
      ],
    };
  },
  computed: {
    userName () {
      return this.$store.state.userName;
    },
  },
  // 注册子组件
  components: {
    Login,
    swiper,
    swiperSlide,
    MerchantVideo
  },
  mounted () {
    this.getConference()
    require('@/assets/img/bg_2.jpg')
    require('@/assets/img/bg_1.jpg')
  },
  methods: {
    async getConference () {
      const { data } = await getTopListApi()
      if (data.code === 0) {
        let value = JSON.parse(JSON.stringify(data.data))
        this.items = value.map((item, index) => {
          if (index === 0 || index === 3) {
            item.conferenceImg = 'merchant1'
          } else if (index === 1 || index === 4) {
            item.conferenceImg = 'merchant2'
          } else if (index === 2 || index === 5) {
            item.conferenceImg = 'merchant3'
          }
          return item
        })
      } else {
        this.$message.error(data.message)
      }
    },
    jumpUrl (url) {
      this.$router.push(url)
    },
    showVideo () {
      this.dialogVisible = true
    },
    //下载app
    downloadApp () {
      console.log('----', this.userName)
      if (!this.userName) {
        this.loginVisible(true)
        return
      }
      this.$router.push("/mall");
    },

    // 登录弹框事件
    loginVisible (status) {
      this.loginIsShow = status;
    },
    /**
     * 招商会详情
     */
    jump2conferenceDetail(id) {
      this.$router.push({
        name: 'ConferenceDetail',
        query: {
          id
        }
      })
    },
    //查看更多按钮
    enter_news () {
      this.$router.push("/conference/list");
    },
    /**
// 		 * 视频指导
// 		 */
    handleVisible () {
      this.dialogVisible = !this.dialogVisible;
    },
    jump2casesDetail(id) {
      this.$router.push({
        name: `CasesDetail${id}`,
      })
    },
  },
};
</script>

<style scoped lang="scss">
.top-bg {
  &.bg_1 {
    height: 800px;
    background: url('~@/assets/img/bg_1.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }
  .top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .top-title {
    font-size: 70px;
    color: #ffffff;
    letter-spacing: 15.56px;
    text-align: justify;
    margin-top: 326px;
  }
  .top-text {
    width: 100%;
    height: 37px;
    text-align: center;
    margin: 20px 0 80px;
  }
}
.in-btn {
  width: 280px;
  height: 59px;
  background: linear-gradient(270deg, #ff8600 0%, #ff4a2d 100%);
  box-shadow: 0px 10px 15px 0px rgba(255, 84, 37, 0.3);
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  margin-top: 77px;
}
.in-btn:hover {
  cursor: pointer;
}
.in-btn > i {
  display: block;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
  margin-left: 10px;
}
.top-subTitle {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.flex {
  display: flex;
  align-items: center;
}
.sub-box {
  width: 203px;
  height: 42px;
  background: rgba(255, 255, 255, 0.26);
  border-radius: 21px;
  color: #ffffff;
}
.sub-content {
  font-size: 26px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 37px;
  letter-spacing: 19px;
  text-shadow: 0px 10px 20px #283865;
}
.sub-line {
  width: 1px;
  height: 25px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  margin: 0 30px;
}
.nav-box {
  height: 120px;
  background: #f7f8f9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  // font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 24px;
}
.nav-list {
  width: 264px;
  height: 54px;
  border-right: 1px solid #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-list:last-child {
  border-right: 0;
}
.nav-text {
  margin-left: 20px;
}
.swiper-box {
  position: relative;
  border-radius: 9px;
  overflow: hidden;
  .last-shadow {
    position: absolute;
    top: 0;
    right: 0;
    width: 66px;
    bottom: 0;
    z-index: 999;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #FFFFFF 100%);
  }
}
.gray-bg {
  background: #f7f8f9;
}
.justifyEnd {
  justify-content: end;
}
.gome_introduce {
  width: 1200px;
  margin: auto;
  position: relative;
  .top_card {
    padding-top: 50px;
    height: 123px;
    opacity: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .top_card-entitle {
      height: 94px;
      font-size: 78px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: rgba(31, 35, 41, 0.08);
      line-height: 94px;
    }
    .top_card-title {
      margin-top: -29px;
      font-size: 40px;
      font-family: HiraginoSansGB-W6, HiraginoSansGB;
      font-weight: normal;
      line-height: 40px;
      color: #1f2329;
    }
    .bottom_line {
      width: 60px;
      height: 8px;
      background: linear-gradient(90deg, #f6ba4f 0%, #ea8427 100%);
      border-radius: 4px;
      margin-top: 10px;
    }
  }
  .bottom_card {
    display: flex;
    align-items: flex-start;
    .bottom-left-card {
      margin-right: 242px;
    }
    .bottom-left-card1 {
      margin-right: 52px;
    }
    .bottom-left-card-title {
      width: 210px;
      font-size: 30px;
      font-family: HiraginoSansGB-W6, HiraginoSansGB;
      font-weight: normal;
      color: #1f2329;
      line-height: 30px;
      margin-top: 80px;
    }
    .bottom-left-card-line {
      width: 40px;
      height: 8px;
      background: linear-gradient(90deg, #f6ba4f 0%, #ea8427 100%);
      border-radius: 4px;
      margin-top: 10px;
    }
    .bottom-left-card-content {
      width: 601px;
      height: 223px;
      font-size: 20px;
      // font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 40px;
      margin-top: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .bottom-left-card-introduce {
      width: 549px;
      height: 380px;
      font-size: 20px;
      // font-family: MicrosoftYaHei;
      color: #666666;
      line-height: 40px;
      margin-top: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bottom-right-card {
      margin-top: 33px;
    }
    .bottom-right-card1 {
      margin-top: 80px;
    }
    .bottom-right-card-img {
      width: 228px;
      height: 494px;
    }
    .bottom-right-card-photo {
      width: 600px;
      height: 450px;
    }
  }
}
.introduce-height {
  height: 870px;
  background-color: #ffffff;
}
.investment {
  height: 1575px;
}
.platform {
  height: 718px;
}
.bottom-carousel-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.gome_project-content-body {
  width: 1000px;
}
.downAppBtn {
  width: 280px;
  height: 59px;
  background: linear-gradient(270deg, #ff8600 0%, #ff4a2d 100%);
  box-shadow: 0px 10px 15px 0px rgba(255, 84, 37, 0.3);
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  margin-top: 60px;
}
.downAppBtn:hover {
  cursor: pointer;
}
.downAppBtn > i {
  display: block;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border: 2px solid #ffffff;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
  margin-left: 10px;
}
//招商入驻指引

.bottom-carousel-card {
  width: 100%;
  margin-top: 60px;
  .carousel-card {
    width: 339px;
    height: 420px;
    border-radius: 10px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .carousel-info {
    position: absolute;
    z-index: 10;
    color: #ffffff;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .carousel-info-title {
    margin-top: 67px;
    // margin-left: 26px;
    // width: 288px;
    height: 42px;
    font-size: 32px;
    // font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 42px;
    white-space: nowrap; /*把文本强制显示在一行*/
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .carousel-info-content {
    margin-top: 20px;
    margin-left: 26px;
    width: 225px;
    height: 56px;
    font-size: 16px;
    // font-family: MicrosoftYaHei;
    color: #ffffff;
    line-height: 28px;
    text-align: center;
  }
  .applyBtn {
    width: 147px;
    height: 42px;
    border-radius: 24px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 108px auto 0 auto;
  }
}
.text {
  overflow: hidden;
  text-overflow: ellipsis; /*超出部分显示...*/
}
.swiper-slide {
  // width: 300px; /*设为固定值*/
  width: auto; /*根据内容调整宽度*/
}
// 为轮播图按钮添加样式
.carousel-button {
  width: 64px;
  height: 64px;
  position: absolute;
  top: 60%;
  transform: translate(0, -50%);
  z-index: 10;
  color: #000000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .el-button {
    width: 100%;
    height: 100%;
    font-size: 10px;
    background-color: #e8e8e8;
    color: #000000 !important;
    border: 0px !important;
  }
}
.prev {
  left: -31px;
}
.next {
  right: -31px;
}

//招商会

.gome_news-entitle {
  font-size: 78px;
  font-family: PingFang SC;
  text-align: center;
  font-weight: 700;
  line-height: 94px;
  color: rgba(31, 35, 41, 0.1);
}
.gome_news-title {
  margin-top: -29px;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: #1f2329;
  opacity: 1;
}
.news-content-con {
  width: 380px;
  height: 512px;
  background: url('~@/assets/img/stores_bg2.png') no-repeat center 0;
  box-shadow: 0px 4px 9px 0px rgba(233,237,241,1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  //margin-right: 30px;
  //padding: 60px 30px 40px 30px;
  transition: transform .3s ease-in-out;
  .news-content-con-sign {
    transition: all .2s ease-in-out;
  }
}
.top {
  margin-top: 80px;
}
.between {
  flex-wrap: wrap;
  justify-content: space-between;
}
.news-content-con:hover {
  cursor: pointer;
  box-shadow: 0px 9px 13px 0px rgba(217,222,227,1);
  //top: -20px;
  transform: translateY(-20px);
  .news-content-con-sign {
    background: linear-gradient(270deg, #ff8600 0%, #ff4a2d 100%);
    color: #ffffff;
  }
}
.news-content-box {
  width: 320px;
  padding: 60px 30px 0px 30px;
}
.news-img {
  width: 320px;
  height: 167px;
  //padding: 60px 30px 30px 30px;
}
.news-content-con-title {
  width: 100%;
  margin-top: 30px;
  font-size: 14px;
  // font-family: MicrosoftYaHei;
  color: #666666;
}
.news-content-con-title-name {
  width: 100%;
  height: 45px;
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #1f2329;
  line-height: 45px;
  overflow: hidden;
  white-space: nowrap; /*把文本强制显示在一行*/
  text-overflow: ellipsis; /*超出部分显示...*/
}
.news-content-con-title-des {
  line-height: 19px;
}
.news-content-con-title-time {
  line-height: 19px;
  margin-top: 16px;
}
// 添加立即报名
.news-content-con-sign {
  width: 147px;
  height: 42px;
  border-radius: 24px;
  border: 1px solid #ff8600;
  // font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #ff8600;
  line-height: 19px;
  margin-top: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.news-content-con-sign:hover {
  width: 147px;
  height: 42px;
  background: linear-gradient(270deg, #ff8600 0%, #ff4a2d 100%);
  border-radius: 24px;
  font-size: 14px;
  // font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 19px;
  cursor: pointer;
}
.gome_news-more {
  width: 280px;
  height: 48px;
  border-radius: 24px;
  border: 1px solid #ff8600;
  font-size: 16px;
  // font-family: MicrosoftYaHei;
  color: #ff8600;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 40px auto 0 auto;
}
//案例
.top75 {
  margin-top: 75px;
}
.case-bottom {
  // display: flex;
  // width: 100%;
  width: 1200px;
  overflow: auto;
}
.case-bottom::-webkit-scrollbar {
  display: none; // 重点
}
.case-bottom:last-child {
  margin-right: 0px;
}
.case-content-con {
  display: inline-block;
  width: 373px;
  // width: calc(100% - 120px) / 3;
  height: 523px;
  background: #fafafa;
  border-radius: 10px;
  margin-right: 40px;
  cursor: pointer;
  border-radius: 9px;
  overflow: hidden;
  transition: all .3s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 4px 0px #e9edf1;
  }
  &:last-of-type {
    margin-right: 0;
  }
}
.case-img {
  width: 100%;
  // width: 385px;
  height: 201px;
}
.case-content-box {
  width: 330px;
  padding: 40px 28px 26px 28px;
}
.case-content-title {
  width: 100%;
  height: 30px;
  font-size: 30px;
  font-family: HiraginoSansGB-W6, HiraginoSansGB;
  font-weight: normal;
  color: #1f2329;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap; /*把文本强制显示在一行*/
  text-overflow: ellipsis; /*超出部分显示...*/
}
.case-content-des {
  margin-top: 16px;
  width: 100%;
  // height: 74px;
  font-size: 14px;
  // font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis; /*超出部分显示...*/
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.case-content-time {
  margin-top: 16px;
  height: 19px;
  font-size: 14px;
  // font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 19px;
}
.case-content-sign {
  width: 91px;
  height: 24px;
  font-size: 18px;
  // font-family: MicrosoftYaHei;
  color: #eb8729;
  line-height: 24px;
  margin-top: 66px;
  cursor: pointer;
}
//多平台支持
.box-out {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 67px;
}
.code-box {
  width: 300px;
  height: 380px;
  text-align: center;
  background-image: url('~@/assets/img/card_bg.png');
  background-position: right;
  box-shadow: 0 30px 50px 0 rgba(206, 206, 206, 0.3);
  border-radius: 26px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .code-title {
    width: 207px;
    height: 45px;
    background: url('~@/assets/img/merchant_9.png') no-repeat center 0;
    font-size: 16px;
    color: #ffffff;
    display: inline-block;
    position: relative;
    top: -7px;
    line-height: 45px;
  }

  .code-pic {
    width: 160px;
    height: 160px;
    margin: 70px auto 25px;
  }

  .code-text {
    font-size: 12px;
    color: #999999;
  }
}
.code-right {
  margin-right: 149px;
}
.code-info {
  width: 120px;
  height: 16px;
  font-size: 12px;
  // font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 16px;
}
.videoBtn {
  width: 60px;
  height: 60px;
  background: rgba($color: #000000, $alpha: 0.4);
  border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.play {
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-left: 18px solid white;
  border-bottom: 11px solid transparent;
  margin-left: 24px;
  opacity: 1;
}
::v-deep {
  .is-in-stage {
    transform: translateX() scale(1) !important;
  }
}
</style>
